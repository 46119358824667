
var isMobile = new Object();
isMobile.Android = function() {
  return navigator.userAgent.match(/Android/i);
};

isMobile.BlackBerry = function() {
  return navigator.userAgent.match(/BlackBerry/i);
};
isMobile.iOS = function() {
  return navigator.userAgent.match(/iPhone|iPad|iPod/i);
};


isMobile.iPad = function() {
  return navigator.userAgent.match(/iPad/i);
};
isMobile.Opera = function() {
  return navigator.userAgent.match(/Opera Mini/i);
};
isMobile.Windows = function() {
  return navigator.userAgent.match(/IEMobile/i);
};

isMobile.Firefox = function() {
  return navigator.userAgent.match(/Firefox/gi);
};
isMobile.InternetExplorer = function() {
  return navigator.userAgent.match(/MSIE/gi);
};
isMobile.Opera = function() {
  return navigator.userAgent.match(/Opera/gi);
};
isMobile.Safari = function() {
  return navigator.userAgent.match(/Safari/gi);
};
isMobile.any = function() {
  return (
    isMobile.Android() ||
    isMobile.BlackBerry() ||
    isMobile.iOS() ||
    isMobile.Opera() ||
    isMobile.Windows()
  );
};
if (isMobile.any() && isMobile.iPad() == null) {
  //return;
}

var ua = window.navigator.userAgent;
var is_ie = /MSIE|Trident/.test(ua);

/*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
var winWT = $(window).innerWidth();
var winHT = $(window).innerHeight();
var basePath = 'https://www.sebamedindia.com/';
//alert(winWT);
//alert(winHT);

var scrollMagicController =  new ScrollMagic.Controller();

var disableScrolling = function(){
  var x=window.scrollX;
  var y=window.scrollY;
  window.onscroll=function(){window.scrollTo(x, y);};
}
var enableScrolling = function(){
  window.onscroll=function(){};
}
window.onbeforeunload = function () {
  window.scrollTo(0, 0);
}

$(document).ready(function(){

  /*$('.slimmenu').slimmenu(
  {
      resizeWidth: '1023',
      collapserTitle: '',
      animSpeed: 'medium',
      easingEffect: null,
      indentChildren: false,
      childrenIndenter: '&nbsp;'
  });*/

  /* Desktop Menu On Scroll */

  var headerScrollPos = 50;
  function desktopNav(){
    if ($(window).scrollTop() >= headerScrollPos) {
      $('.logo img').attr("src", basePath+"/assets/images/ft-sebamed-logo.png");
      TweenMax.to('.logo',0.3,{'max-width':'70px'}, 'sticky');
      TweenMax.to('header',0.5,{'background':'#ffffff'}, 'sticky');
      $('header').addClass('sticky');
    }
    else {
      $('.logo img').attr("src",basePath+"/assets/images/logo.png");
      TweenMax.to('.logo',0.3,{'max-width':'130px'}, 'nonSticky');
      TweenMax.to('header',0.5,{'background':'none'}, 'nonSticky');
      $('header').removeClass('sticky');
    }
  }

  function mobileNav(){
    
    if ($(window).scrollTop() >= headerScrollPos) {
      $('.logo img').attr("src",basePath+"/assets/images/ft-sebamed-logo.png");
      TweenMax.to('.logo',0.3,{'max-width':'75px'}, 'sticky');
      TweenMax.to('header',0.5,{'background':'#ffffff'}, 'sticky');
      TweenMax.to('#nav-icon1 span',0.5,{'background':'#83bde4'}, 'sticky');
      $('header').addClass('sticky');
    }
    else {
      $('.logo img').attr("src",basePath+"/assets/images/logo.png");
      TweenMax.to('.logo',0.3,{'max-width':'75px'}, 'nonSticky');
      TweenMax.to('header',0.5,{'background':'none'}, 'nonSticky');
      TweenMax.to('#nav-icon1 span',0.5,{'background':'#ffffff'}, 'nonSticky');
      $('header').removeClass('sticky');
    }
  }

  $(window).scroll(function(){
    if(isMobile.any()){
      mobileNav();
    }else{
      desktopNav();
    }
  });


  var mobileNavSetup = function(){
    winWT = $(window).innerWidth();
    winHT = $(window).innerHeight();
    var navWT = $("nav").innerWidth();
    $("#nav-icon1").removeClass("open");
    TweenMax.to("nav",0.5,{left:-navWT+"px", ease:Power2.easeInOut,});
  };
  

  if(winWT <= 1023){
    mobileNavSetup();
    $('.slimmenu').slimmenu(
    {
        resizeWidth: '1023',
        collapserTitle: '',
        animSpeed: 'medium',
        easingEffect: null,
        indentChildren: false,
        childrenIndenter: '&nbsp;'
    });
  }

  $("#nav-icon1").click(function(){
    if($(this).hasClass('open')){
      $(this).removeClass("open");
      mobileNavSetup();
      enableScrolling();
      setBgForNavBtn();
    }else{
      $(this).addClass("open");
      TweenMax.set("nav",{display:"block",});
      TweenMax.to("nav",0.5,{left:"0px", ease:Power2.easeInOut, onComplete:function(){
        setBgForNavBtn();
      }});
      disableScrolling();
    }
    
  });


  function setBgForNavBtn(){
    if(winWT <= 1023){
      if($('#nav-icon1').hasClass('open')){
        $('.nav_close_btn').css({'background':'#ffffff'});
      }else{
        $('.nav_close_btn').css({'background':'none'});
      }
    }
  }


  

  /******************* S E A R C H **********************/
  $('.hd_icon_hld .search').bind('click', function(){
    TweenMax.set('#search_panel',{display:'block',onComplete:function(){
      TweenMax.to('#search_panel',0.3,{ opacity:1, ease:Sine.easeInOut});
      //$('.find_textbox').trigger('focus');
      if(!is_ie){
        $('.find_textbox').trigger('focus');
      }
    }})
    disableScrolling();
  });

  var searchBtn = $('.search_con').find('.button');
  /*$('.find_textbox').on('focus', function(){
    
    TweenMax.set(searchBtn,{opacity:0, display:'inline-block'});
    TweenMax.to(searchBtn,0.3,{ opacity:1, ease:Sine.easeInOut});
  });*/

  $('.search_overlay').bind('click', function(){
    TweenMax.to('#search_panel',0.3,{ opacity:0, ease:Sine.easeInOut,onComplete:function(){
      TweenMax.set('#search_panel',{display:'none'})
      TweenMax.set(searchBtn,{opacity:0, display:'none'});
    }});
    enableScrolling();
  });

  $('.close_search').bind('click', function(){
    TweenMax.to('#search_panel',0.3,{ opacity:0, ease:Sine.easeInOut,onComplete:function(){
      TweenMax.set('#search_panel',{display:'none'})
      TweenMax.set(searchBtn,{opacity:0, display:'none'});
    }});
    enableScrolling();
  });

  

  /******************* S E A R C H **********************/

  /************************************* BLOG SLIDER  *********************************/
  var blogDetArr = $('.blog_det');
  var blogsLength = blogDetArr.length;
  
  $('.blog_thumb_hld').slick({
    infinite: false,
    dots: false,
    fade:false,
    slidesToShow: 1,
    slidesToScroll: 1
  });

  $('.pager span:last-child').append(blogsLength);
  $(blogDetArr[0]).css({'display':'block'});

  $('.blog_thumb_hld').on('beforeChange', function(event, slick, currentSlide, nextSlide){
    $(blogDetArr).css({'display':'none'});
    $(blogDetArr[nextSlide]).fadeIn(300);
    $('.pager span:first-child').empty();
    $('.pager span:first-child').append('0'+ (nextSlide+1));
  });

  
  /************************************* BLOG SLIDER  *********************************/

  $('.play').magnificPopup({
      delegate: 'a',
      type: 'iframe',
      mainClass: 'mfp-img-mobile',
      closeOnBgClick: true,
      iframe: {
        markup: '<div class="mfp-iframe-scaler">' +
          '<div class="mfp-close"></div>' +
          '<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>' +
          '</div>',

        patterns: {
          youtube: {
            index: 'youtube.com/',
            id: 'v=',
            src: '//www.youtube.com/embed/%id%?autoplay=1'
          },
          vimeo: {
            index: 'vimeo.com/',
            id: '/',
            src: '//player.vimeo.com/video/%id%?autoplay=1'
          },
          gmaps: {
            index: '//maps.google.',
            src: '%id%&output=embed'
          }
        },

        srcAction: 'iframe_src',
      }
  });

  function onOrientationChange() {
    if (window.orientation == 90 || window.orientation == -90) {
        $(".rotate_msg").css("display", "block");
        disableScrolling();
    } else {
        $(".rotate_msg").css("display", "none");
        enableScrolling();
    }
  }
  window.addEventListener("orientationchange", onOrientationChange, false);
  if (isMobile.any()) {
      onOrientationChange();
  }

  /**************  S P L I T    T E X T  ************************/
  function arrayMe(string) {
    // For all matching elements
    $(string).each(function() {
      // Get contents of string
      var myStr = $(this).html();
      // Split myStr into an array of characters
      myStr = myStr.split("");
      // Build an html string of characters wrapped in  tags with classes
      var myContents = "";
      for (var i = 0, len = myStr.length; i < len; i++) {
        if(myStr[i]==' '){
          myContents += '<span>&nbsp;</span>';
        }else{
          myContents += '<span>' + myStr[i] + '</span>';
        }
      }
      // Replace original string with constructed html string
      $(this).html(myContents);
    });
  }
  // Calling arrayMe on page load, on class "sectTl"
  var myStringType = $('.sectTl');
	//arrayMe(myStringType);

  /**************  S P L I T    T E X T  ************************/

  function commonAnimRef(){
    /***************************   Blog Anim  ********************************/
    var blogTL = new TimelineLite();
    blogTL.fromTo('#blog h2', 0.3, {opacity:0, y:30},{opacity:1, y:0,ease:Sine.easeInOut})
          .fromTo('.blog_slider',0.5,{opacity:0, y:50},{opacity:1, y:0,ease:Sine.easeInOut},'blogOverlay')
          .fromTo('.animOverlay',0.8,{height:'100%'},{height:0,ease:Sine.easeInOut},'blogOverlay-=0.001')
          .fromTo('.blog_slider img',1,{scale:1.1},{scale:1,ease:Sine.easeInOut},'blogOverlay-=0.001')
          .fromTo('#blog .blog_det h3',0.5,{opacity:0, x:50},{opacity:1, x:0})
          .fromTo('#blog .blog_det .button',0.1,{opacity:0, x:50},{opacity:1, x:0},'-=0.2')
    var tweenBlog = new ScrollMagic.Scene({
      triggerElement: "#blog", 
      triggerHook: 0.6
    })
    .setTween(blogTL)
    //.addIndicators()
    .addTo(scrollMagicController);
    /***************************   Blog Anim  ********************************/
  }
  if(!isMobile.any()){
    commonAnimRef();
  }
});












